import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Fargate 上で動くコンテナアプリケーションに SessionManager で接続する",
  "date": "2020-09-18T09:04:04.000Z",
  "slug": "entry/2020/09/18/180404",
  "tags": ["medley"],
  "hero": "./2020_09_18.png",
  "heroAlt": "fargate"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`自己紹介`}</h1>
    <p>{`株式会社メドレーのエンジニア阪本です。`}</p>
    <p>{`9 月に入っても暑い日が続く中、皆さんはいかがお過ごしでしょうか。`}</p>
    <p>{`前回のブログでも書きましたが私は野球観戦（虎党）が趣味で、毎日の試合結果に一喜一憂しています。
このブログの執筆時点ではセ・リーグは首位巨人にマジックが点灯し、残試合 50 を切った状況でのゲーム差 10。優勝を目指す阪神にとっては厳しい状況となりましたが、残り直接対決をすべて勝てば可能性は見えてくるはず。ここは諦めずに応援しようと思います。`}</p>
    <p>{`次の記事を書く頃には何らかの決着が着いていると思いますので、その時には喜びのメッセージが書ける事を願っています。`}</p>
    <h1>{`はじめに`}</h1>
    <p>{`突然ですが、皆さんは`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/fargate/"
      }}>{`Fargate`}</a>{`を使いこなしていますか？
今まで ECS(EC2)での運用がメインでしたが、ジョブメドレーのシステムでも Fargate に触れる機会が増えてきました。
筆者自身は初めての Fargate でしたが、EC2 の存在が無くなることに不思議な感覚を覚えつつも、管理するものが減って運用が楽になったと実感しています。`}</p>
    <p>{`しかし、EC2 が無くなった事によりサーバ内にターミナルで入る手段を失いました。
公式の`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/blogs/startup/techblog-container-fargate-1/#04"
      }}>{`ガイド`}</a>{`では`}</p>
    <blockquote>
      <p parentName="blockquote">{`質問２：コンテナの中に ssh や docker exec で入ることは出来ますか？`}</p>
      <p parentName="blockquote">{`こちら現状サポートしておりませんが、コンテナワークロードでは「`}<a parentName="p" {...{
          "href": "https://12factor.net/ja/dev-prod-parity"
        }}>{`同一の環境でしっかりテストを行う`}</a>{`」ことや「`}<a parentName="p" {...{
          "href": "https://12factor.net/ja/logs"
        }}>{`ログを外部に全て出す`}</a>{`」ことがベストプラクティスとされていて、コンテナに入る必要性を出来る限り減らす方が将来的にも好ましいです。`}</p>
    </blockquote>
    <p>{`と「事前にテストを十分に実施する」「ログは(S3 や CloudWatchLogs など)外部に出力する」
さえ出来ていれば入る必要が無いはずと記載されているものの・・・心配性な自分は不測の事態が発生した時の事を考えてサーバに入る手段を求めてしまいます。`}</p>
    <p>{`そこで代替手段を模索していた所、`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/systems-manager/features/#Session_Manager"
      }}>{`SessionManager`}</a>{`を使えば可能との文献を見つけました。
すでに SessionManager 自体は EC2 に対して運用を行っていたため導入の敷居は低いと考え、この手段を採用することにしました。`}</p>
    <h1>{`Session Manager とは？`}</h1>
    <p>{`Session Manager とは AWS の Systems Manager サービスの 1 機能で、AWS 上で管理しているサーバ（マネージドインスタンス）に対してターミナルのアクセスを可能にするものです。`}</p>
    <p>{`ターミナルへのアクセス手段は、よくある手段だと SSH がありますが
この方法は SSH ポートを外部に開放する必要があるため攻撃の対象になりやすく、あまり好ましくありません。`}</p>
    <p>{`それに比べて Session Manager は SSH ポートの開放は不要でサーバから見てアウトバウンド方向の HTTPS 通信の確保で済む為、外部からの攻撃も受けず安全にアクセス経路の確保が実現できます。
※通信経路の確保以外にも IAM ロールの設定などが必要となります。詳しくは`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/systems-manager/latest/userguide/systems-manager-quick-setup.html#quick-setup-instance-profile"
      }}>{`こちら`}</a>{`を参考`}</p>
    <p>{`「AWS 上で管理しているサーバ」と先に記載しましたが、このサーバは EC2 インスタンスに限らないのが本記事の重要なポイントになります。
オンプレミスなサーバも AWS 上で管理されているのであれば、SessionManager エージェントをインストールする事により管理対象に含めることが可能になるのです。`}</p>
    <p>{`今回は EC2 で動いていた SessionManager エージェントを Fargate で動くコンテナにインストールする事によりコンテナ自体をサーバと見立ててマネージドインスタンスとし、SessionManager でアクセスする事を目指します。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200918/20200918142724.png",
      "alt": "20200918142724.png"
    }}></img>
    <h1>{`対応`}</h1>
    <h2>{`Systems Manager インスタンス枠をスタンダードからアドバンスドに変更する ／ AWS コンソールでの設定`}</h2>
    <p>{`オンプレミスなサーバに SessionManager にてアクセスする場合、Systems Manager のオンプレミスインスタンスティアを`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/systems-manager/pricing/#On-Premises_Instance_Management"
      }}>{`スタンダードからアドバンスドへと変更する必要`}</a>{`があります。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200918/20200918142745.png",
      "alt": "20200918142745.png"
    }}></img>
    <p>{`この変更作業は AWS コンソールから行う事になりますが、アドバンスドへの変更に当たって既存のマネージドインスタンスに影響することはありません。
ただし、この逆の場合、スタンダードに戻す際には考慮が必要となるので`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/systems-manager/latest/userguide/systems-manager-managed-instances-advanced-reverting.html"
      }}>{`こちら`}</a>{`を参照ください。`}</p>
    <h2>{`SSM Agent のインストール ／ コンテナに対する設定`}</h2>
    <p>{`ここからは実際に動くコンテナに対する設定になります。
AWS のドキュメント`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/systems-manager/latest/userguide/sysman-manual-agent-install.html"
      }}>{`手動でインストール SSM エージェント EC2 で Linux のインスタンス`}</a>{`を参考に、OS に合った方法で SessionManager エージェントのインストールを行います。`}</p>
    <p>{`インストール作業には通信などの時間が必要となるので、事前にコンテナイメージにインストールする形としました。`}</p>
    <h2>{`コンテナをマネージドインスタンスとして登録する ／ コンテナに対する設定`}</h2>
    <p>{`コンテナをマネージドインスタンスとして登録するため、コンテナのスタートアップ(Entrypoint)にて下記スクリプトを実行します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# 1. ハイブリッドアクティベーションの作成`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`SSM_ACTIVATE_INFO`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`aws ssm create-activation --iam-role service-role/AmazonEC2RunCommandRoleForManagedInstances --registration-limit `}<span parentName="span" {...{
              "className": "token number"
            }}>{`1`}</span>{` --region ap-northeast-1 --default-instance-name medley-blog-fargate-container`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 2. アウトプットからアクティベーションコード/ID の抽出`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`SSM_ACTIVATE_CODE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token builtin class-name"
            }}>{`echo`}</span>{` $SSM_ACTIVATE_INFO `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` jq -r `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'.ActivationCode'`}</span><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`SSM_ACTIVATE_ID`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token builtin class-name"
            }}>{`echo`}</span>{` $SSM_ACTIVATE_INFO `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` jq -r `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'.ActivationId'`}</span><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 3. コンテナ自身をマネージドインスタンスへの登録`}</span>{`
amazon-ssm-agent -register -code `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SSM_ACTIVATE_CODE`}</span>{` -id `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SSM_ACTIVATE_ID`}</span>{` -region `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ap-northeast-1"`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 4. SessionManager エージェントの起動`}</span>{`
amazon-ssm-agent `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span></code></pre></div>
    <p>{`これらのコマンドについては各行ごとに説明します`}</p>
    <h3>{`1.ハイブリッドアクティベーションの作成`}</h3>
    <p>{`マネージドインスタンス登録に必要なアクティベーションコード/ID 取得のため、ハイブリッドアクティベーションの登録を行います。
後々 AWS コンソールにてインスタンスの識別ができるように、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--default-instance-name`}</code>{`オプションにて`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`medley-blog-fargate-container`}</code>{`という名前を付与しています。`}</p>
    <p>{`このコマンドのアウトプットにて下記のような JSON が返される為、一旦変数に格納しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ActivationId"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<<アクティベーション ID>>"`}</span>{`, `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ActivationCode"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<<アクティベーションコード>>"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`また`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--iam-role`}</code>{`オプションでサービスロール AmazonEC2RunCommandRoleForManagedInstances を使っています。
このロールがまだ存在しない場合、AWS コンソールからハイブリッドアクティベーションから IAM ロール →「必要な権限を備えたシステムのデフォルトコマンド実行ロールを作成」により作成してください。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200918/20200918142823.png",
      "alt": "20200918142823.png"
    }}></img>
    <h3>{`2. アウトプットからアクティベーションコード/ID の抽出`}</h3>
    <p>{`前項のアウトプットからアクティベーションコード/ID を個々の変数に分離しています。`}</p>
    <h3>{`3. コンテナ自身をマネージドインスタンスへの登録`}</h3>
    <p>{`取得したアクティベーションコード/ID を利用し、コンテナ自身をマネージドインスタンスとして登録します。
環境変数`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`AWS_DEFAULT_REGION`}</code>{`のように AWS の Credential にてデフォルトの Region 設定があった場合でも、このコマンドではオプションで Region 指定が必須となるのでご注意ください。`}</p>
    <h3>{`4. SessionManager エージェントの起動`}</h3>
    <p>{`最後に SessionManager エージェントを起動します。
これにより AWS との通信が確立され、SessionManager が利用できるようになります。`}</p>
    <p>{`この状態でマネージドインスタンス画面にて目的のインスタンスが「オンライン」であれば設定が完了です。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200918/20200918142901.png",
      "alt": "20200918142901.png"
    }}></img>
    <h2>{`接続確認`}</h2>
    <p>{`設定が完了したので、実際に SessionManager にて接続してみます。
AWS コンソールのセッションマネージャからセッションの開始を選択し、名前が事前に登録した`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`medley-blog-fargate-container`}</code>{`であるものを選択します。
外見は他の EC2 と変わらない表示ですが、この手段で登録したものはインスタンス ID が`}<strong parentName="p">{`mi`}</strong>{`から始まる ID になります(通常の EC2 は`}<strong parentName="p">{`i`}</strong>{`から始まる ID)。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200918/20200918142910.png",
      "alt": "20200918142910.png"
    }}></img>
    <p>{`後は普段通りにセッションを開始するとコンテナ内へのアクセスが開始されます。
これで EC2 の無い Fargate 上のコンテナに対してもターミナルに入ることができました。`}</p>
    <h1>{`運用してみての感想`}</h1>
    <p>{`Fargate 上のコンテナに直接入ることにより、今まで出来なかった
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`top`}</code>{`コマンドによるプロセス状態の確認
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`df`}</code>{`コマンドによる Disk 容量の確認
などの環境調査が出来るようになりました。
利用頻度が多い訳ではありませんが、調査の選択肢を増やす事により有事の際の早期対応に繋げれられていると感じています。`}</p>
    <h1>{`注意点`}</h1>
    <p>{`ここからは運用に当たっての注意点について数点紹介します。`}</p>
    <h2>{`料金`}</h2>
    <p>{`EC2 での SessionManager と違い、`}<strong parentName="p">{`この方法で登録したサーバへの SessionManager 通信は料金が発生します`}</strong>{`。
正確には
「SessionManager エージェントが起動し AWS と通信が確立している状態」
での時間課金で SessionManager での接続有無は問いません。
よって、常時必要ではない場合は SessionManager エージェントを止めて節約する方法を検討してください。`}</p>
    <h2>{`アクティベーションやマネージドインスタンスがリストに残る`}</h2>
    <p>{`コンテナ終了時でも AWS コンソールのハイブリッドアクティベーション一覧やマネージドインスタンス一覧に表示が残ってしまうようです。
一覧の上限の記載は見つからなかったものの、無限に増えるとも思えないので適時 Lambda を使って削除しています。`}</p>
    <h2>{`SessionManager での操作について`}</h2>
    <p>{`EC2 インスタンスに対しての SessionManager と同様ですが、セッション開始直後のユーザは ssm-user 固定になるようです。
特定ユーザでの操作が必要となる場合、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`su`}</code>{`コマンドでのユーザ切り替えが必要になります。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`今回のようにメドレーでは新たな技術を取り入れつつ、サービスの安定を目的とした様々な施策を導入しています。
こういった働き方に興味を持たれた方、まずは下記リンクからお気軽にお話しませんか？`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      